@import 'themes/default';


.rs-picker-menu.rs-picker-inline {
  position: relative;
  box-shadow: none;
}

.rs-picker-menu {
  text-align: left;
  background-color: #fff;
  transition: none;
}

.rs-picker-menu .rs-picker-search-bar {
  position: relative;
  padding: 6px 12px 12px;
}

.rs-picker-menu .rs-picker-search-bar::after {
  content: '\f002';
  font-family: 'rsuite-icon-font', sans-serif;
  font-style: normal;
  position: absolute;
  width: 14px;
  color: #c5c6c7;
  font-size: 14px;
  line-height: 1.42857143;
  top: 14px;
  right: 24px;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  display: block;
  width: 100%;
  color: #575757;
  background-image: none;
  border: 1px solid #e5e5ea;
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  padding: 7px 11px;
  line-height: 1.42857143;
  min-width: 200px;
  padding-right: 36px;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

.rs-picker-cascader-menu-items {
  padding: 6px 0;
}

.rs-picker-cascader-menu-items > div::before, .rs-picker-cascader-menu-items > div::after {
  content: ' ';
  display: table;
}

.rs-picker-cascader-menu-items > div::after {
  clear: both;
}

.rs-picker-cascader-menu-column {
  float: left;
  overflow-y: auto;
}

.rs-picker-cascader-menu-items ul, .rs-picker-cascader-menu-items li {
  margin: 0;
  padding: 0;
  list-style: none;
}

[tabindex='-1'] {
  outline: none;
}

.rs-checkbox {
  position: relative;
  display: block;
}

.rs-check-item .rs-checkbox-checker {
  padding: 0;
}

// check the checker
.rs-checkbox-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 36px;
  min-height: 36px;
  line-height: 1;
  position: relative;
}

.ReactVirtualized__Grid .rs-check-item .rs-checkbox-checker > label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rs-picker-cascader-menu .rs-check-item .rs-checkbox-checker > label {
  padding-right: 26px;
}

.rs-check-item .rs-checkbox-checker > label {
  position: relative;
  cursor: pointer;
  display: block;
  padding: 8px 12px 8px 38px;
  transition: color 0.3s linear, background-color 0.3s linear;
  line-height: 20px;
}


// check cursor
.rs-checkbox label {
  line-height: 1.14285714;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}

.rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
  left: 12px;
}

.rs-checkbox-wrapper {
  position: absolute;
  width: 16px;
  height: 16px;
  display: inline-block;
  left: 10px;
  top: 10px;
}

.rs-check-item .rs-checkbox-checker > label {
  position: relative;
  cursor: pointer;
  display: block;
  padding: 8px 12px 8px 38px;
  transition: color 0.3s linear, background-color 0.3s linear;
  line-height: 20px;
}

.rs-checkbox-wrapper::before, .rs-checkbox-wrapper .rs-checkbox-inner::before, .rs-checkbox-wrapper .rs-checkbox-inner::after {
  width: 16px;
  height: 16px;
}

.rs-checkbox-wrapper::before, .rs-checkbox-wrapper::after, .rs-checkbox-wrapper .rs-checkbox-inner::before, .rs-checkbox-wrapper .rs-checkbox-inner::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
}

.rs-checkbox-wrapper::after {
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}


.rs-checkbox-wrapper::before {
  border: 1px solid #3498ff;
  background-color: transparent;
  border-radius: 3px;
  transform: scale(1);
  opacity: 0.7;
  visibility: hidden;
  transition: transform 0.2s linear, opacity 0.2s linear;
}


.rs-checkbox-wrapper [type='checkbox'] {
  width: 0;
  height: 0;
  opacity: 0;
}

.rs-checkbox-wrapper .rs-checkbox-inner::before {
  border: 1px solid #d9d9d9;
  background-color: transparent;
  border-radius: 3px;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
}


.rs-checkbox-wrapper .rs-checkbox-inner::after {
  opacity: 0;
  transform: rotate(45deg) scale(0);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.rs-picker-cascader-search-panel {
  max-height: 300px;
  overflow: auto;
}

.rs-picker-multi-cascader-menu .rs-picker-cascader-row {
  padding-left: 0;
}

.rs-picker-cascader-row {
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
}

.rs-picker-multi-cascader-menu .rs-picker-cascader-col {
  padding: 0;
}

.rs-picker-cascader-col {
  padding: 8px 0;
  display: inline-block;
}

.rs-picker-cascader-col strong {
  color: #3498ff;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #3498ff;
  background-color: #3498ff;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border: solid #fff;
  width: 6px;
  height: 9px;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 1;
}

.rs-checkbox-wrapper .rs-checkbox-inner::after {
  opacity: 0;
  transform: rotate(45deg) scale(0);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) scale(1);
}

.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 0 2px;
  transform: rotate(0deg) scale(1);
  width: 10px;
  margin-top: 0;
  margin-left: 3px;
}


.rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
  cursor: not-allowed;
}

.rs-checkbox-disabled > .rs-checkbox-checker > label {
  color: #c5c6c7;
}

.rs-picker-search-bar-input:focus-visible, .rs-picker-search-bar-input:hover {
  outline: rgba(64, 169, 255, 0.6) auto 1px;
}

.rs-picker-cascader-col {
  padding: 8px 0;
  display: inline-block;
}

.rs-picker-multi-cascader-menu .rs-picker-cascader-col {
  padding: 0;
}

.rs-picker-cascader-col:after {
  content: "/";
  padding-left: 4px;
  padding-right: 4px;
}

.rs-picker-cascader-col:last-child:after {
  display: none;
}
